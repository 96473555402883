<script setup>
import { filename } from 'pathe/utils';
import { getMaskImageStyle } from '@@/utils/CommonUtils';

const config = useRuntimeConfig().public;

// HACK: Work around for dynamic images in Vite which aren't supported as easily as with Webpack
// SEE: https://www.lichter.io/articles/nuxt3-vue3-dynamic-images/
const glob = import.meta.glob('@/assets/img/*LogoMarkWhite.png', { eager: true });
const images = Object.fromEntries(
  Object.entries(glob).map(([key, value]) => [filename(key), value.default]),
);

const copyrightMessage = computed(() => `${new Date().getFullYear()} ${config.siteName}. Cloudnine Weather LLC. All Rights Reserved.`);

const links = {
  appLink: {
    text: 'App',
    to: '/app',
  },
  climateLink: {
    text: 'Climate',
    to: '/news/post/opensnow-electricity-usage-100-renewable',
  },
  gettingStartedLink: {
    target: '_blank',
    text: 'Getting Started',
    to: 'https://support.opensnow.com/getting-started',
  },
  privacyPolicyLink: {
    target: '_blank',
    text: 'Privacy Policy',
    to: 'https://support.opensnow.com/legal/privacy',
  },
  shopLink: {
    target: '_blank',
    text: 'Shop',
    to: 'https://shop.opensnow.com',
  },
  supportLink: {
    target: '_blank',
    text: 'Support',
    to: 'https://support.opensnow.com/',
  },
  teamLink: {
    text: 'Team',
    to: '/team',
  },
  termsOfUseLink: {
    target: '_blank',
    text: 'Terms of Use',
    to: 'https://support.opensnow.com/legal/terms',
  },
};

const listOfFooterLinks = computed(() => {
  const allAccessLink = {
    text: 'All-Access',
    to: '/allaccess',
  };

  const whatsNewLink = {
    text: "What's New",
    to: '/news/company-updates',
  };

  const featureGuidesLink = {
    target: '_blank',
    text: 'Feature Guides',
    to: 'https://support.opensnow.com/feature-guides',
  };

  const flakesPodcastLink = {
    target: '_blank',
    text: 'Flakes Podcast',
    to: 'https://open.spotify.com/show/2hDI6YOg6PLXUKYoOrWV1s?si=dVM_oEi6TfmqlyHmzLEqVw&nd=1',
  };

  const aboutLink = {
    text: 'About',
    to: '/about',
  };

  const careersLink = {
    target: '_blank',
    text: 'Careers',
    to: 'https://support.opensnow.com/legal/careers',
  };

  const contactLink = {
    target: '_blank',
    text: 'Contact',
    to: 'https://support.opensnow.com/contact-us/contact',
  };

  const advertiseLink = {
    target: '_blank',
    text: 'Advertise',
    to: 'https://support.opensnow.com/contact-us/advertising-on-opensnow',
  };

  const partnershipPolicyLink = {
    target: '_blank',
    text: 'Partnership Policy',
    to: 'https://support.opensnow.com/legal/partnerships',
  };

  // NOTE: An array of arrays is returned so that the OpenSnow footer links can be rendered on
  // multiple lines with precise control over which links appear on which links.
  return [
    [
      allAccessLink,
      links.appLink,
      whatsNewLink,
    ],
    [
      links.gettingStartedLink,
      featureGuidesLink,
      flakesPodcastLink,
    ],
    [
      aboutLink,
      links.teamLink,
      careersLink,
      contactLink,
      links.climateLink,
      links.shopLink,
      advertiseLink,
    ],
    [
      links.supportLink,
      links.termsOfUseLink,
      links.privacyPolicyLink,
      partnershipPolicyLink,
    ],
  ];
});

const siteLogoImageUrl = computed(() => images[`${config.siteName}LogoMarkWhite`]);

const socialMediaLinks = [
  {
    style: {
      backgroundColor: 'currentColor',
      ...getMaskImageStyle('https://blizzard.opensnow.com/icons/fa/brands/facebook.svg'),
    },
    url: 'https://www.facebook.com/OpenSnow',
  }, {
    style: {
      backgroundColor: 'currentColor',
      ...getMaskImageStyle('https://blizzard.opensnow.com/icons/fa/brands/instagram.svg'),
    },
    url: 'https://www.instagram.com/opensnow/',
  }, {
    style: {
      backgroundColor: 'currentColor',
      ...getMaskImageStyle('https://blizzard.opensnow.com/icons/fa/brands/x-twitter.svg'),
    },
    url: 'https://x.com/OpenSnow',
  },
  {
    style: {
      backgroundColor: 'currentColor',
      ...getMaskImageStyle('https://blizzard.opensnow.com/icons/fa/brands/youtube.svg'),
    },
    url: 'https://www.youtube.com/@Open-Snow',
  },
];
</script>

<template>
  <footer class="brand-color tw-py-8 tw-px-3.5">
    <div class="tw-text-center">
      <img
        :alt="config.siteName"
        :class="[$style.siteLogoImage, 'tw-inline-block']"
        :src="siteLogoImageUrl"
      >
    </div>
    <div
      v-for="(footerLinks, index) in listOfFooterLinks"
      :key="index"
      :class="[$style.footerLinksRow, 'tw-mx-auto', 'tw-my-4']"
    >
      <ul class="tw-flex tw-flex-nowrap tw-justify-center">
        <li
          v-for="footerLink in footerLinks"
          :key="footerLink.text"
          :class="[
            $style.footerLinkItem,
            'tw-inline-block lg:tw-mx-3',
          ]"
        >
          <NuxtLink
            :target="footerLink.target"
            :to="footerLink.to"
          >
            {{ footerLink.text }}
          </NuxtLink>
        </li>
      </ul>
    </div>
    <DownloadAppButtons
      :always-use-app-store-dark-button="true"
    />
    <ul class="tw-flex tw-justify-center tw-my-4">
      <li
        v-for="socialMediaLink in socialMediaLinks"
        :key="socialMediaLink.url"
        class="tw-mx-4"
      >
        <a
          :href="socialMediaLink.url"
          target="_blank"
        >
          <span
            class="tw-inline-block tw-w-8 tw-h-8 tw-align-middle"
            :style="socialMediaLink.style"
          />
        </a>
      </li>
    </ul>
    <p class="tw-text-xs tw-p-6 tw-text-center">
      &copy;&nbsp;{{ copyrightMessage }}
    </p>
  </footer>
</template>

<style module>
.siteLogoImage {
  height: 4.375rem;
}

.footerLinksRow {
  max-width: 45.625rem;
}

.footerLinkItem {
  font-size: 0.8125rem;
  line-height: 2rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
</style>
