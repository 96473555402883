<script>
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { isScreenSm } from '@@/utils/CommonUtils';

export const bannerTypes = {
  disclaimer: 'd',
  error: 'e',
  info: 'i',
  membership: 'm',
  warning: 'w',
};
</script>

<script setup>
/* eslint vue/prop-name-casing: off */

const props = defineProps({
  action: {
    type: String,
    default: '',
  },
  action_url: {
    type: String,
    default: '',
  },
  icon: {
    type: [String, Array],
    default: null,
  },
  iconClass: {
    type: [String, Array],
    default: null,
  },
  is_dismissable: {
    type: Boolean,
    default: true,
  },
  message: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'm',
    validator(value) {
      // SEE: https://github.com/cloudninewx/OpenMountain-API/blob/develop/models/User.js#L200-L207
      return Object.values(bannerTypes).includes(value);
    },
  },
});

const $emit = defineEmits(['dismiss']);

const actionButtonType = computed(() => props.type === bannerTypes.disclaimer
  ? ButtonTypes.help
  : ButtonTypes.bannerBuyNow);

const buttonClass = computed(() => {
  if (props.type === bannerTypes.disclaimer) {
    return 'text-regular-color';
  }

  if (props.type === bannerTypes.warning) {
    return 'tw-text-black';
  }

  return 'tw-text-white';
});

const buttonDisplayBlock = computed(() => isScreenSm());

const classNames = computed(() => {
  const typeClassNames = {
    [bannerTypes.disclaimer]: 'tw-border-2 disclaimer',
    [bannerTypes.info]: 'tw-text-white tw-border-2 background-color-light-blue border-color-saturated-blue-50',
    [bannerTypes.error]: 'tw-text-white tw-border-2 background-color-light-red border-color-saturated-red',
    [bannerTypes.membership]: 'tw-text-white tw-border-2 background-color-saturated-teal border-color-dark-teal',
    [bannerTypes.warning]: 'tw-text-black tw-border-2 background-color-light-orange border-color-saturated-orange',
  };

  return [
    'tw-relative',
    'tw-p-4 tw-rounded-lg',
    'tw-text-base tw-font-medium tw-text-center',
    typeClassNames[props.type],
  ];
});

const iconClassNames = computed(() => {
  const iconClassNames = ['tw-mr-1'];

  if (Array.isArray(props.iconClass)) {
    iconClassNames.push(...props.iconClass);
  }
  else if (typeof props.iconClass === 'string') {
    iconClassNames.push(props.iconClass);
  }

  return iconClassNames;
});
</script>

<template>
  <p :class="classNames">
    <CardCloseButton
      v-if="is_dismissable"
      :button-class="buttonClass"
      @click="$emit('dismiss')"
    />
    <font-awesome-icon
      v-if="icon"
      :class="iconClassNames"
      :icon="icon"
    />
    {{ message }}
    <Button
      v-if="action && action_url"
      class="tw-block md:tw-inline-block tw-mt-2 md:tw-mt-0 md:tw-ml-2"
      :display-block="buttonDisplayBlock"
      :to="action_url"
      :type="actionButtonType"
    >
      {{ action }}
    </Button>
  </p>
</template>
