<script setup>
import { onClickOutside } from '@vueuse/core';
import { useTemplateRef } from 'vue';
import { ButtonTypes } from '@@/components/Common/Button.vue';

const props = defineProps({
  additionalDropdownClass: {
    type: String,
    default: '',
  },
  buttonType: {
    type: String,
    default: ButtonTypes.secondary,
  },
  cardProps: {
    type: Object,
    default() {
      return {
        hasBodyPaddingSmall: true,
      };
    },
  },
  closeOnClickAway: {
    type: Boolean,
    default: true,
  },
  isActive: {
    type: Boolean,
    default: undefined,
  },
  isDropdownAbsolute: {
    type: Boolean,
    default: false,
  },
  isMenuFullWidth: {
    type: Boolean,
    default: false,
  },
  menuStyle: {
    type: Function,
    default() {
      return null;
    },
  },
  position: {
    type: String,
    default: 'left',
  },
  showBackgroundBlur: {
    type: Boolean,
    default: false,
  },
  showMenu: {
    type: Boolean,
    default: false,
  },
  zIndex: {
    type: String,
    default: 'tw-z-20',
  },
});

const $emit = defineEmits(['close', 'open']);

const button = useTemplateRef('button');
const dropdown = useTemplateRef('dropdown');
const slots = useSlots();
const $style = useCssModule();

const isMenuOpen = ref(false);

const backgroundClassNames = computed(() => `tw-fixed tw-inset-0 tw-z-20 ${$style.background}`);
const dropdownClass = computed(() => `${props.isDropdownAbsolute
  ? 'tw-absolute'
  : 'tw-relative'} ${props.zIndex} tw-overflow-visible ${props.additionalDropdownClass}`,
);

const showAnchor = computed(() => !!slots.anchor?.());
const showButton = computed(() => !!slots.button?.());

const menuClass = computed(() => {
  let position = 'tw-left-0';

  if (props.position === 'right') {
    position = 'tw-right-0';
  }
  else if (props.position && props.position !== 'right' && props.position !== 'left') {
    // Allow parent to specify custom position
    position = props.position;
  }

  return [
    'tw-absolute tw-top-full',
    props.zIndex,
    position,
    props.isMenuFullWidth ? 'tw-w-full' : null,
    'tw-mt-1',
    isMenuOpen.value ? $style.showMenu : $style.hideMenu,
    $style.slideTransition,
    props.showBackgroundBlur && isMenuOpen.value ? 'tw-delay-300' : null,
  ];
});

const closeMenu = () => isMenuOpen.value = false;

const handleClickAway = (e) => {
  if (props.closeOnClickAway) {
    // Add a reference to the dropdown that was closed so that the click can be properly handled by
    // parent components.
    e.dropdownMenuClosed = dropdown.value;
    closeMenu();
  }
};

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

let stop = null;

watch(() => isMenuOpen.value, (newValue) => {
  if (newValue) {
    $emit('open');
    stop = onClickOutside(dropdown, handleClickAway);
  }
  else {
    $emit('close');

    if (stop) {
      stop();
      stop = null;
    }
  }

  if (props.showButton) {
    button.value.blur();
  }
});

watch(() => props.showMenu, (newValue, oldValue) => {
  if (newValue && !oldValue) {
    isMenuOpen.value = true;
  }
  else if (!newValue && oldValue) {
    isMenuOpen.value = false;
  }
}, { immediate: true });
</script>

<template>
  <div
    ref="dropdown"
    :class="dropdownClass"
  >
    <a
      v-if="showAnchor"
      class="link-color-brand"
      href="javascript:void(0)"
      @click="toggleMenu"
    >
      <slot name="anchor" />
    </a>
    <Button
      v-if="showButton"
      ref="button"
      :active="isMenuOpen || isActive"
      :can-show-chip-active-icon="false"
      :type="buttonType"
      @click="toggleMenu"
    >
      <slot name="button" />
    </Button>
    <transition
      enter-from-class="fade-out"
      :enter-active-class="$style.backgroundEnterTransition"
      leave-active-class="fade-leave-transition"
      leave-to-class="fade-out"
    >
      <div
        v-if="isMenuOpen && showBackgroundBlur"
        id="dropdownMenuBackgroundBlur"
        :class="backgroundClassNames"
        @click="closeMenu"
      />
    </transition>
    <Card
      ref="menu"
      :class="menuClass"
      :style="menuStyle()"
      v-bind="cardProps"
    >
      <template #body>
        <slot name="menu" />
      </template>
    </Card>
  </div>
</template>

<style module>
.backgroundEnterTransition {
  transition: opacity var(--fade-transition-time) ease-in-out;
}

.background {
  backdrop-filter: blur(1px);
  background-color: rgb(0 0 0 / 50%);
}

.hideMenu {
  max-height: 0;
  min-height: 0;
  opacity: 0;
}

.slideTransition {
  overflow: hidden;
  transition-duration: 250ms;
  transition-property: min-height, opacity;
  transition-timing-function: ease-in-out;
}

.showMenu {
  max-height: none;
  min-height: var(--dropdown-menu-show-menu-min-height, 20rem);
  opacity: 1;
}
</style>
