/* eslint camelcase: off */
import { useNuxtApp } from 'nuxt/app';
import { defineStore } from 'pinia';
import { useCookieWrapper } from '~/composables/useCookieWrapper';
import { useMetaStore } from '~/stores/Meta';
import { useUserStore } from '~/stores/User';

const activeListCookieName = 'activelist';

const addFavorite = async (params = {}) => {
  const {
    $api,
    id,
    list_ids,
    mutation,
    path,
    reset_list_membership,
    self,
  } = params;

  // If the caller specified a list ID then use that when adding the favorite. Otherwise use the
  // current active list ID selected by the user. This allows the caller to add an item to more
  // than one favorite list.
  const body = {
    list_ids: list_ids || [self.user.list_id],
    reset_list_membership,
  };

  // Pass the active list ID on the query so that favorites from the active list are returned in
  // the response body.
  //
  // This ensures that the summer cams/trail maps are returned and displayed in the UI when a
  // cam/trail map that was in both lists is removed from the summer list while viewing the summer
  // favorite cams/trail maps. If the summer list ID were not specified on the query then the
  // winter favorite cams/trail maps would be returned in the response and then accidentally
  // displayed in the UI.
  const query = { list_id: self.user.list_id };
  const url = `${path}/${id}`;

  const response = await $api.put(url, query, body);
  self[mutation](response);

  return response;
};

const fetchFavorites = async (params = {}) => {
  const userStore = useUserStore();

  const {
    $api,
    mutation,
    path,
    payload,
    self,
  } = params;

  if (userStore.isGuest) {
    return null;
  }

  const { saveToStore = true } = payload;
  const { cache_key, units } = userStore.user;
  const { list_id } = self.user;

  const query = {
    cache_key,
    list_id: payload.list_id ?? list_id,
  };

  if (mutation === 'saveUserFavoritesLocations') {
    query.units = units;
  }

  const response = await $api.get(path, query);

  if (saveToStore) {
    self[mutation](response);
  }

  return response;
};

const removeFavorite = async (params = {}) => {
  const {
    $api,
    id,
    list_ids,
    mutation,
    path,
    self,
  } = params;

  // If the caller specified a list ID then use that when adding the favorite. Otherwise use the
  // current active list ID selected by the user. This allows the caller to add an item to more
  // than one favorite list.
  const body = list_ids ? { list_ids } : { list_ids: [self.user.list_id] };
  const url = `${path}/${id}`;

  const response = await $api.delete(url, undefined, body);

  self[mutation](response);
};

const updateFavorites = async (params = {}) => {
  const {
    $api,
    body,
    mutation,
    path,
    self,
  } = params;

  const { list_id } = self.user;
  const query = { list_id };

  const response = await $api.post(path, query, body);

  self[mutation](response);
};

const getFavoriteListsForResource = (self, listIds, id) => self.user?.favoriteIds?.lists
  .reduce((acc, list) => {
    if (list[listIds].includes(id)) {
      acc.push(list.list_id);
    }

    return acc;
  }, []);

export const useUserFavoritesStore = defineStore('userFavorites', {
  state: () => ({
    user: null,
    ui: null,
  }),

  actions: {
    async addFavoriteCam({ id, list_ids, reset_list_membership }) {
      const { $api } = useNuxtApp();

      return addFavorite({
        $api,
        id,
        list_ids,
        mutation: 'saveUserFavoritesCams',
        path: '/user/favorites/cams',
        reset_list_membership,
        self: this,
      });
    },

    async removeFavoriteCam({ id, list_ids }) {
      const { $api } = useNuxtApp();

      return removeFavorite({
        $api,
        id,
        list_ids,
        mutation: 'saveUserFavoritesCams',
        path: '/user/favorites/cams',
        self: this,
      });
    },

    async fetchFavoriteCams(payload = {}) {
      const { $api } = useNuxtApp();

      return fetchFavorites({
        $api,
        mutation: 'saveUserFavoritesCams',
        path: '/user/favorites/cams',
        payload,
        self: this,
      });
    },

    async updateFavoriteCams(payload = {}) {
      const { $api } = useNuxtApp();

      return updateFavorites({
        $api,
        body: { cam_ids: payload.favorites.map(({ id }) => id) },
        mutation: 'saveUserFavoritesCams',
        path: '/user/favorites/cams',
        self: this,
      });
    },

    async addFavoriteDailyRead({ id, list_ids, reset_list_membership }) {
      const { $api } = useNuxtApp();

      return addFavorite({
        $api,
        id,
        list_ids,
        mutation: 'saveUserFavoritesDailyReads',
        path: '/user/favorites/daily-reads',
        reset_list_membership,
        self: this,
      });
    },

    async removeFavoriteDailyRead({ id, list_ids }) {
      const { $api } = useNuxtApp();

      return removeFavorite({
        $api,
        id,
        list_ids,
        mutation: 'saveUserFavoritesDailyReads',
        path: '/user/favorites/daily-reads',
        self: this,
      });
    },

    async fetchFavoriteDailyReads(payload = {}) {
      const { $api } = useNuxtApp();

      return fetchFavorites({
        $api,
        mutation: 'saveUserFavoritesDailyReads',
        path: '/user/favorites/daily-reads',
        payload,
        self: this,
      });
    },

    async updateFavoriteDailyReads(payload = {}) {
      const { $api } = useNuxtApp();

      return updateFavorites({
        $api,
        body: { daily_read_ids: payload.favorites.map(({ id }) => id) },
        mutation: 'saveUserFavoritesDailyReads',
        path: '/user/favorites/daily-reads',
        self: this,
      });
    },

    async addFavoriteLocation({ id, list_ids, reset_list_membership }) {
      const { $api } = useNuxtApp();

      const { location_id, locations } = await addFavorite({
        $api,
        id,
        list_ids,
        mutation: 'saveUserFavoritesLocations',
        path: '/user/favorites/locations',
        reset_list_membership,
        self: this,
      });

      const favoriteLocation = locations.find((location) => location.id === location_id);
      return favoriteLocation;
    },

    async removeFavoriteLocation({ id, list_ids }) {
      const { $api } = useNuxtApp();

      return removeFavorite({
        $api,
        id,
        list_ids,
        mutation: 'saveUserFavoritesLocations',
        path: '/user/favorites/locations',
        self: this,
      });
    },

    async addFavoriteCustomLocation(payload = {}) {
      const { $api } = useNuxtApp();
      const path = `/user/favorites/locations/custom${payload.id ? `/${payload.id}` : ''}`;

      let { list_ids } = payload;
      list_ids = list_ids ? { list_ids } : { list_ids: [this.user.list_id] };

      const { location_id, locations } = await $api.put(path, undefined, { ...payload, ...list_ids });
      this.saveUserFavoritesLocations({ locations });

      const customLocation = locations.find((location) => location.id === location_id);
      return customLocation;
    },

    async fetchFavoriteLocations(payload = {}) {
      const { $api } = useNuxtApp();

      return fetchFavorites({
        $api,
        mutation: 'saveUserFavoritesLocations',
        path: '/user/favorites/locations',
        payload,
        self: this,
      });
    },

    async updateFavoriteLocations(payload = {}) {
      const { $api } = useNuxtApp();

      return updateFavorites({
        $api,
        body: { location_ids: payload.favorites.map(({ id }) => id) },
        mutation: 'saveUserFavoritesLocations',
        path: '/user/favorites/locations',
        self: this,
      });
    },

    async addFavoriteSeasonPass({ id, list_ids, reset_list_membership }) {
      const { $api } = useNuxtApp();

      return addFavorite({
        $api,
        id,
        list_ids,
        mutation: 'saveUserFavoritesSeasonPasses',
        path: '/user/favorites/season-passes',
        reset_list_membership,
        self: this,
      });
    },

    async removeFavoriteSeasonPass({ id, list_ids }) {
      const { $api } = useNuxtApp();

      return removeFavorite({
        $api,
        id,
        list_ids,
        mutation: 'saveUserFavoritesSeasonPasses',
        path: '/user/favorites/season-passes',
        self: this,
      });
    },

    async fetchFavoriteSeasonPasses(payload = {}) {
      const { $api } = useNuxtApp();

      return fetchFavorites({
        $api,
        mutation: 'saveUserFavoritesSeasonPasses',
        path: '/user/favorites/season-passes',
        payload,
        self: this,
      });
    },

    async updateFavoriteSeasonPasses(payload = {}) {
      const { $api } = useNuxtApp();

      return updateFavorites({
        $api,
        body: { season_pass_ids: payload.favorites.map(({ id }) => id) },
        mutation: 'saveUserFavoritesSeasonPasses',
        path: '/user/favorites/season-passes',
        self: this,
      });
    },

    async addFavoriteTrailMap({ id, list_ids, reset_list_membership }) {
      const { $api } = useNuxtApp();

      return addFavorite({
        $api,
        id,
        list_ids,
        mutation: 'saveUserFavoritesTrailMaps',
        path: '/user/favorites/trail-maps',
        reset_list_membership,
        self: this,
      });
    },

    async removeFavoriteTrailMap({ id, list_ids }) {
      const { $api } = useNuxtApp();

      return removeFavorite({
        $api,
        id,
        list_ids,
        mutation: 'saveUserFavoritesTrailMaps',
        path: '/user/favorites/trail-maps',
        self: this,
      });
    },

    async fetchFavoriteTrailMaps(payload = {}) {
      const { $api } = useNuxtApp();

      return fetchFavorites({
        $api,
        mutation: 'saveUserFavoritesTrailMaps',
        path: '/user/favorites/trail-maps',
        payload,
        self: this,
      });
    },

    async updateFavoriteTrailMaps(payload = {}) {
      const { $api } = useNuxtApp();

      return updateFavorites({
        $api,
        body: { trail_map_ids: payload.favorites.map(({ id }) => id) },
        mutation: 'saveUserFavoritesTrailMaps',
        path: '/user/favorites/trail-maps',
        self: this,
      });
    },

    async fetchActiveList(payload = {}) {
      const { user } = payload;
      this.saveUser(user);

      // If the active list cookie is found and it contains the id of a valid list then
      // set the user's active list in the state so that it will be used when fetching their
      // favorites. By doing this the user's preferred active list will be persisted across page
      // views and when they return to the site.

      const activeList = useCookieWrapper(activeListCookieName);

      if (activeList?.value && user?.lists?.find(({ id }) => id === activeList.value)) {
        this.saveActiveList(activeList.value);
      }
    },

    async fetchUserFavoriteIds() {
      const { $api } = useNuxtApp();
      const userStore = useUserStore();

      if (userStore.isGuest) {
        return;
      }

      const response = await $api.get('/user/favorites/ids');

      this.saveUserFavoriteIds(response);
    },

    /**
     * Update active list in the store and save to a cookie so the user's selected active list can be
     * persisted across page views and when the user returns to the site.
     */
    setActiveList(listId) {
      const userStore = useUserStore();

      if (userStore.isGuest) {
        return;
      }

      this.saveActiveList(listId);

      // HACK: Remove any and all activelist cookies before setting the new one! This is a brute
      // force way to remove all previous activelist cookies which may be present for slightly
      // different domains.
      // SEE: https://github.com/cloudninewx/Projects/issues/981
      if (import.meta.client) {
        const clearActiveListCookie = (domain) => {
          document.cookie = `opensnow-${activeListCookieName}=;domain=.${domain};max-age=0;path=/;`;
          document.cookie = `opensnow-${activeListCookieName}=;domain=${domain};max-age=0;path=/;`;
          document.cookie = `opensnow-${activeListCookieName}=;max-age=0;path=/;`;
        };

        const config = useRuntimeConfig().public;

        if (config?.environment === 'local') {
          clearActiveListCookie('opensnow.local.opensnow.com');
        }
        else if (config?.environment === 'development') {
          clearActiveListCookie('dev.opensnow.com');
        }
        else if (config?.environment === 'test') {
          clearActiveListCookie('test.opensnow.com');
        }
        else if (config?.environment === 'production') {
          clearActiveListCookie('opensnow.com');
        }

        clearActiveListCookie('opensnow.com');
      }

      const cookie = useCookieWrapper(activeListCookieName);
      cookie.value = listId;
    },

    showSelectFavoriteListDialog(props) {
      this.saveUiProperty({ selectFavoriteListDialogProps: props });
    },

    saveActiveList(listId) {
      if (this.user?.list_id) {
        this.user.list_id = listId;
      }
    },

    saveUiProperty(property) {
      const [[key, value]] = Object.entries(property);

      if (!this.ui) {
        this.ui = {};
      }

      this.ui[key] = value;
    },

    saveUser(user) {
      this.user = {
        ...this.user,
        ...user,
      };
    },

    saveUserFavoriteIds({ favorites, lists }) {
      if (!this.user.favoriteIds) {
        this.user.favoriteIds = {};
      }

      this.user.favoriteIds.favorites = favorites;
      this.user.favoriteIds.lists = lists;
    },

    saveUserFavoritesLocations({ locations }) {
      if (!this.user.favorites) {
        this.user.favorites = {};
      }

      this.user.favorites.locations = locations;
    },

    saveUserFavoritesCams({ cams, locations }) {
      if (!this.user.favorites) {
        this.user.favorites = {};
      }

      this.user.favorites.webcams = { cams, locations };
    },

    saveUserFavoritesDailyReads({ daily_reads }) {
      if (!this.user.favorites) {
        this.user.favorites = {};
      }

      this.user.favorites.dailyReads = daily_reads;
    },

    saveUserFavoritesSeasonPasses({ season_passes }) {
      if (!this.user.favorites) {
        this.user.favorites = {};
      }

      this.user.favorites.seasonPasses = season_passes;
    },

    saveUserFavoritesTrailMaps({ locations, trail_maps }) {
      if (!this.user.favorites) {
        this.user.favorites = {};
      }

      this.user.favorites.trailMaps = { locations, trail_maps };
    },
  },

  getters: {
    getActiveList() {
      const userStore = useUserStore();

      if (userStore.isGuest) {
        return null;
      }

      const { list_id } = this?.user || {};
      return this?.user?.lists?.find(({ id }) => id === list_id);
    },

    getFavoriteListsForCam: (state) => (cam) => getFavoriteListsForResource(state, 'cam_ids', cam?.id),
    getFavoriteListsForDailyRead: (state) => (dailyRead) => getFavoriteListsForResource(state, 'daily_read_ids', dailyRead?.id),
    getFavoriteListsForLocation: (state) => (location) => getFavoriteListsForResource(state, 'location_ids', location?.id),
    getFavoriteListsForSeasonPass: (state) => (seasonPass) => getFavoriteListsForResource(state, 'season_pass_ids', seasonPass?.id),
    getFavoriteListsForTrailMap: (state) => (trailMap) => getFavoriteListsForResource(state, 'trail_map_ids', trailMap?.id),

    getLists() {
      return this?.user?.lists;
    },

    getLocationIdsForActiveList() {
      const { id } = this.getActiveList || {};
      const activeList = this.user?.favoriteIds?.lists?.find(({ list_id }) => list_id === id);
      return activeList?.location_ids ?? [];
    },

    getLocationTypeIdsForActiveList() {
      const { id } = this.getActiveList || {};
      const activeList = this.user?.favoriteIds?.lists?.find(({ list_id }) => list_id === id);
      return activeList?.location_type_ids ?? [];
    },

    getSummerLists() {
      const metaStore = useMetaStore();

      const summerListType = metaStore.favorite_list_types
        .find(({ name }) => name === 'Summer');

      const summerList = this.getLists
        ?.find(({ list_type_id }) => list_type_id === summerListType.id);

      return this?.user?.favoriteIds?.lists
        ?.find(({ list_id }) => list_id === summerList?.id);
    },

    isFavoriteCam: (state) => ({ camId }) => {
      const { cam_ids } = state?.user?.favoriteIds?.favorites || {};

      if (cam_ids) {
        return cam_ids.includes(camId);
      }

      return false;
    },

    isFavoriteLocation: (state) => ({ locationId }) => {
      const { location_ids } = state?.user?.favoriteIds?.favorites || {};

      if (location_ids) {
        return location_ids.includes(locationId);
      }

      return false;
    },

    isFavoriteSummerLocation: (state) => ({ locationId }) => {
      const { location_ids } = state.getSummerLists || {};

      if (location_ids) {
        return location_ids.includes(locationId);
      }

      return false;
    },

    isFavoriteSeasonPass: (state) => ({ seasonPassId }) => {
      const { season_pass_ids } = state?.user?.favoriteIds?.favorites || {};

      if (season_pass_ids) {
        return season_pass_ids.includes(seasonPassId);
      }

      return false;
    },

    isFavoriteTrailMap: (state) => ({ trailMapId }) => {
      const { trail_map_ids } = state?.user?.favoriteIds?.favorites || {};

      if (trail_map_ids) {
        return trail_map_ids.includes(trailMapId);
      }

      return false;
    },

    isFavoriteDailyRead: (state) => ({ dailyReadId }) => {
      const { daily_read_ids } = state?.user?.favoriteIds?.favorites || {};

      if (daily_read_ids) {
        return daily_read_ids.includes(dailyReadId);
      }

      return false;
    },
  },
});
